<template>
  <div>
    <v-container> </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "includem",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
      ],
    };
  },

  computed: {},

  mounted() {},

  watch: {},

  methods: {},
};
</script>
